import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ContextProvider } from "./context";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
		<React.StrictMode>
			<ContextProvider>
				<App />
			</ContextProvider>
		</React.StrictMode>
	</BrowserRouter>
);
